import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import useAccountsAndKits from 'services/account/useAccountsAndKits';
import { useSystemInfo } from 'services/system-info/useSystemInfo';
import { getTinyAccountsOrderedByLatestKitResults } from 'store/account';
import { getHomePageTinyAccountId, setHomePageTinyAccountId } from 'store/home';
import {
  getImpersonateId,
  getIsActivePractitioner,
  getIsAdminUser,
  getIsAuthenticated,
  getIsAutoLoggedOut,
  impersonate,
  signIn,
  UserAttributes,
} from 'store/user';
import { Spinfinity } from 'components';
import AuthLayout from 'components/Layout/AuthLayout';
import ResponsiveLayout from 'components/Layout/ResponsiveLayout';
import { useSubdomain } from 'hooks/useSubdomain';
import { useUrlQuery } from 'hooks/useUrlQuery';
import { SplashScreen } from 'views/Auth';
import { GetStarted } from './GetStarted/GetStarted';
import { HomeContent } from './HomeContent/HomeContent';
import jwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/react';
import useImpersonate from 'services/auth/useImpersonate';

const Home = () => {
  const dispatch = useDispatch();
  const { impersonate } = useImpersonate();
  const { state: navigationState } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tinyAccountIdParam = useUrlQuery().get('account');
  useAccountsAndKits();
  useSystemInfo();
  const { isPractitionerPortal, isLoaded: isSubdomainLoaded } = useSubdomain();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const impersonateId = useSelector(getImpersonateId);
  const isActivePractitioner = useSelector(getIsActivePractitioner);
  const isAdmin = useSelector(getIsAdminUser);
  const isAdminOrPractitioner = isActivePractitioner || isAdmin;
  const tinyAccountId = useSelector(getHomePageTinyAccountId);
  const tinyAccounts = useSelector(getTinyAccountsOrderedByLatestKitResults);
  const isAutoLoggedOut = useSelector(getIsAutoLoggedOut);
  // set the home page tiny account
  useEffect(() => {
    if (!tinyAccounts?.length) return;
    // if tinyAccountIdParam or navigation state is present, set that as the home page tiny account
    let newTinyAccountId: string | null = null;
    if (tinyAccountIdParam || navigationState?.tinyAccountId) {
      newTinyAccountId =
        navigationState?.tinyAccountId ?? (tinyAccountIdParam as string);
    } else if (!tinyAccountId) {
      // if no tiny account is set, set the tiny account with the most recent kit as the home page tiny account
      newTinyAccountId = tinyAccounts[0].id;
    }

    if (
      newTinyAccountId !== tinyAccountId &&
      tinyAccounts?.some(account => account.id === newTinyAccountId)
    ) {
      dispatch(setHomePageTinyAccountId(newTinyAccountId));
    }
  }, [
    dispatch,
    isAuthenticated,
    navigationState,
    tinyAccountId,
    tinyAccountIdParam,
    tinyAccounts,
  ]);

  useEffect(() => {
    const signInAndImpersonate = async () => {
      try {
        const jwtToken = searchParams.get('token');
        const impersonateMainAccountId = searchParams.get('impersonate_id');
        const impersonateKitId = searchParams.get('kit_id');
        if (jwtToken && impersonateMainAccountId) {
          const decoded = jwtDecode<UserAttributes>(jwtToken);
          if (!decoded || typeof decoded === 'string') {
            return;
          }

          localStorage.setItem('jwtToken', jwtToken);
          await dispatch(signIn(decoded));
          impersonate(impersonateMainAccountId, impersonateKitId);

          if (impersonateKitId) {
            navigate(`/results/${impersonateKitId}`);
          } else {
            navigate(`/results`);
          }
        }
      } catch (err) {
        const error = err as Error;
        Sentry.captureException(error);
      }
    };
    signInAndImpersonate();
  }, [searchParams]);

  if (isAutoLoggedOut) {
    return (
      <Navigate
        to='/login'
        replace
        state={{
          ...navigationState,
          tinyAccountId: tinyAccountIdParam
            ? tinyAccountIdParam
            : tinyAccountId,
        }}
      />
    );
  }
  if (!isSubdomainLoaded) {
    return (
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='' element={<Spinfinity />} />
        </Route>
      </Routes>
    );
  }
  if (!isAuthenticated) {
    return (
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='' element={<SplashScreen />} />
        </Route>
      </Routes>
    );
  }
  if (isPractitionerPortal && !impersonateId && isAdminOrPractitioner) {
    return <Navigate to='/portal' replace />;
  }

  let HomeScreenAction = null;
  if (!tinyAccounts?.length) {
    HomeScreenAction = <GetStarted />;
  } else if (tinyAccountId) {
    HomeScreenAction = <HomeContent />;
  }

  return (
    <Routes>
      <Route element={<ResponsiveLayout />}>
        <Route path='' element={HomeScreenAction} />
      </Route>
    </Routes>
  );
};

export default Home;
