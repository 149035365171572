import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export const UserRoles = {
  USER: 'user',
  PRACTITIONER: 'practitioner',
  PARTNER: 'partner',
  ADMIN: 'admin',
  IMPERSONATE: 'impersonate',
};
// Types
interface UserState {
  username?: string;
  isAuthenticated: boolean;
  impersonateId?: string;
  hasDeclinedImpersonate?: boolean;
  email?: string;
  phoneNumber?: string;
  practitionerCode?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  role?: string;
  roles?: string[];
  status?: string;
  isAutoLoggedOut?: boolean;
}

export type UserAttributes = {
  id?: string;
  status?: string;
  email?: string;
  role?: string;
  roles?: string[];
  organization?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  practitioner_code?: string;
};

export type SignInPayload = Partial<UserAttributes>;

// State
export const initialState: UserState = {
  isAuthenticated: false,
  isAutoLoggedOut: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signIn(state, action: PayloadAction<SignInPayload>) {
      const attributes = action.payload;
      console.log('signIn', attributes);
      state.isAuthenticated = true;

      state.role = attributes.role;
      state.roles = attributes.roles;
      state.status = attributes.status || 'active';
      state.email = attributes.email;
      state.phoneNumber = attributes.phone_number;
      state.firstName = attributes.first_name;
      state.lastName = attributes.last_name;
      state.username = attributes.id;
      state.practitionerCode = attributes.practitioner_code;
      state.isAutoLoggedOut = false;
      delete state.password;
    },
    signOut(state) {
      return {
        ...initialState,
        email: state.email,
        isAutoLoggedOut: state.isAutoLoggedOut,
      };
    },
    impersonate(state, action: PayloadAction<string>) {
      state.impersonateId = action.payload;
    },
    skipImpersonate(state) {
      state.hasDeclinedImpersonate = true;
      delete state.impersonateId;
    },
    storeEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    saveTempPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    setIsAutoLoggedOut(state) {
      state.isAutoLoggedOut = true;
    },
  },
});

// Selectors
export const getUser = (state: RootState) => state.user;
export const getIsAuthenticated = createSelector(
  getUser,
  user => user.isAuthenticated,
);

export const getRole = createSelector(getUser, user => user.role);
export const getRoles = createSelector(getUser, user => user.roles);
export const getEmail = createSelector(getUser, user => user.email);
export const getPhoneNumber = createSelector(getUser, user => user.phoneNumber);
export const getFirstName = createSelector(getUser, user => user.firstName);
export const getLastName = createSelector(getUser, user => user.lastName);
export const getUsername = createSelector(getUser, user => user.username);
export const getTempPassword = createSelector(getUser, user => user.password);
export const getPractitionerCode = createSelector(
  getUser,
  user => user.practitionerCode,
);

export const getHasTinyHealthEmail = createSelector(
  getEmail,
  email => email?.split('@')[1] === 'tinyhealth.com',
);
export const getIsAdminUser = createSelector(getRoles, roles =>
  roles?.includes('admin'),
);
export const getIsPractitionerUser = createSelector(getRoles, roles =>
  roles?.includes('practitioner'),
);
export const getIsImpersonateUser = createSelector(getRoles, roles =>
  roles?.includes('impersonate'),
);
export const getIsActive = createSelector(
  getUser,
  user => user.status === 'active',
);
export const getIsInactivePractitioner = createSelector(
  [getIsPractitionerUser, getIsActive],
  (isPractitioner, isActive) => {
    return isPractitioner && !isActive;
  },
);
export const getIsActivePractitioner = createSelector(
  [getIsPractitionerUser, getIsActive],
  (isPractitioner, isActive) => {
    return isPractitioner && isActive;
  },
);
export const getCanImpersonate = createSelector(
  [getIsImpersonateUser, getIsPractitionerUser, getIsActive],
  (isImpersonateUser, isPractitioner, isActive) =>
    isImpersonateUser || (isPractitioner && isActive),
);

export const getImpersonateId = createSelector(
  getUser,
  user => user.impersonateId,
);
export const getHasDeclinedImpersonate = createSelector(
  getUser,
  user => user.hasDeclinedImpersonate,
);
export const getRequiresImpersonateId = createSelector(
  [getCanImpersonate, getImpersonateId, getHasDeclinedImpersonate],
  (roleCanImpersonate, impersonateId, hasDeclinedImpersonate) => {
    return roleCanImpersonate && !impersonateId && !hasDeclinedImpersonate;
  },
);
export const getIsAutoLoggedOut = createSelector(
  getUser,
  user => user.isAutoLoggedOut,
);

export const {
  signIn,
  signOut,
  impersonate,
  setIsAutoLoggedOut,
  skipImpersonate,
  saveTempPassword,
  storeEmail,
} = userSlice.actions;
export default userSlice.reducer;
