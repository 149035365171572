import { useState } from 'react';

import useVaginalType from 'services/gutType/useVaginalType';

import { TaxaFilters, taxaCategoryFilters } from 'components/TaxaFilters';
import { InsightsBacteriaList } from '../Insights';
import { Spinfinity } from 'components';

import styles from './VaginalType.module.scss';
import { KitType } from 'types/Kit';

import { lowRelativeAbundanceLimit } from 'assets/constants';

export const VaginalHealthBacteria = () => {
  const [taxaCategories, setTaxaCategories] = useState(taxaCategoryFilters);
  const { data: kitData } = useVaginalType({
    relativeAbundanceLimit: lowRelativeAbundanceLimit,
  });

  if (!kitData?.length) return <Spinfinity />;
  return (
    <div className={styles.cstContainer}>
      <TaxaFilters
        kitType={KitType.VAGINAL}
        taxaFilters={taxaCategories}
        setFilters={setTaxaCategories}
      />
      <h5>Species Detail</h5>
      {kitData[0].taxaData && (
        <InsightsBacteriaList
          data={kitData[0].taxaData}
          filters={taxaCategories}
          type={KitType.VAGINAL}
        />
      )}
    </div>
  );
};
