import AuthLayout from 'components/Layout/AuthLayout';
import { useSubdomain } from 'hooks/useSubdomain';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getIsAuthenticated, getCanImpersonate } from 'store/user';
const NoAuthRoute = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const roleCanImpersonate = useSelector(getCanImpersonate);
  const { isPractitionerPortal, isLoaded } = useSubdomain();
  const location = useLocation();
  const from = location.state?.from;
  if (!isLoaded) return null;
  if (isAuthenticated) {
    if (from) {
      return <Navigate to={from} replace />;
    } else if (roleCanImpersonate && isPractitionerPortal) {
      return <Navigate to='/portal' replace />;
    } else {
      return <Navigate to='/' replace />;
    }
  } else {
    return <Outlet />;
  }
};
export default NoAuthRoute;
