import { NavLink, useLocation, NavLinkProps } from 'react-router-dom';
import { ComponentType } from 'react';
import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';

import { GenericLinkProps, TextLink, TextLinkProps } from '@repo/ui';
const NavLinkWrapper: ComponentType<GenericLinkProps> = props => {
  return <NavLink {...props} />;
};

export const TextLinkWithTracking = ({
  label,
  onClick,
  ...props
}: TextLinkProps) => {
  const { trackEvent } = useAnalytics();
  const { pathname } = useLocation();

  // Determine urlString from href or to
  let urlString: string | undefined;
  if ('to' in props) {
    const { to } = props;
    if (typeof to === 'string') {
      urlString = to;
    }
  }
  const hasUrl = !!urlString;
  const isContentOnly = !hasUrl && !onClick;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(e);
    }
    trackEvent('Clicked Button', {
      text: label,
      href: hasUrl ? (urlString as string) : '',
      isExternal: props.external ?? false,
      variety: 'TextLink',
    });
  };
  if (isContentOnly) {
    return (
      <TextLink
        {...props}
        to={undefined}
        from={undefined}
        onClick={undefined}
        label={label}
        role='button'
        contentOnly
      />
    );
  } else if (hasUrl && urlString) {
    return (
      <TextLink
        {...props}
        label={label}
        LinkComponent={hasUrl && !props.external ? NavLinkWrapper : undefined}
        to={urlString}
        contentOnly={false}
        onClick={handleClick}
        from={pathname}
      />
    );
  } else {
    return (
      <TextLink
        {...props}
        label={label}
        LinkComponent={hasUrl && !props.external ? NavLinkWrapper : undefined}
        to={undefined}
        contentOnly={false}
        onClick={handleClick}
        from={undefined}
      />
    );
  }
};
export default TextLinkWithTracking;
