import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

export const getVaginalTypeResponse = async (
  kitId: string,
  relativeAbundanceLimit?: number,
) => {
  const query_string = relativeAbundanceLimit
    ? `?relativeAbundanceLimit=${relativeAbundanceLimit}`
    : '';

  const response = await API.get(
    apiName,
    `${apiBasePath}/vaginalType/${kitId}${query_string}`,
    {},
  );
  return response?.result;
};
export const getVaginalTypeQuery = (
  kitId?: string | null,
  relativeAbundanceLimit?: number,
) => ({
  queryKey: [apiName, 'vaginalType', kitId],
  queryFn: () => kitId && getVaginalTypeResponse(kitId, relativeAbundanceLimit),
});
const useVaginalType = ({
  relativeAbundanceLimit,
}: {
  relativeAbundanceLimit?: number;
} = {}) => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const vaginalTypeQuery = useSuspenseQuery(
    getVaginalTypeQuery(currentKitId, relativeAbundanceLimit),
  );

  return vaginalTypeQuery;
};

export default useVaginalType;
