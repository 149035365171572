import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useConsults from 'services/general/consultsList';
import useMainAccount from 'services/account/useMainAccount';
import useConsultAgents from 'services/general/consultAgentsList';
import { capitalizeFirstLetter } from 'helpers';
import { CONSULT_ACTIVE_STATUS, LINKS } from 'assets';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { getConsultCreditsCount } from 'store/account';
import { Consult } from 'types/Consult';
import { MembershipPlan } from 'types/MainAccount';

import {
  ButtonColors,
  ButtonSizes,
  ContainerSizes,
  LinkButton,
  FloatingCTA,
  PageContainer,
} from 'components';
import { Icon, Typography } from '@repo/ui';
import { ConsultCard } from './ConsultCard/ConsultCard';
import { ConsultInfo } from './ConsultsInfo/ConsultsInfoPage';
import { ConsultPricing } from './ConsultPricing/ConsultPricing';
import { BookInAdvance } from './BookInAdvance/BookInAdvance';
import HeaderIllustration from 'components/Header/HeaderIllustration';
import { GenericSummaryCardWithImage } from 'views/Results/Summary/SummaryCards/GenericSummaryCardWithImage/GenericSummaryCardWithImage';
import consultsIllustration from 'assets/images/summary/consults.jpg';
import ConsultsSVG from 'assets/images/consults.svg';

import styles from './Consults.module.scss';

export const getAgentNameFromEmail = (email?: string) => {
  const AGENT_NAMES = ['amy', 'jennie', 'danielle', 'taylor'];
  const name = email?.split('@')[0];
  return !email || !name || !AGENT_NAMES.includes(name)
    ? 'our team'
    : capitalizeFirstLetter(name);
};

export const useConsultLink = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const { data: mainAccountData } = useMainAccount();

  const consultLink = useMemo(() => {
    const query = new URLSearchParams();
    const consultCreditsCount = mainAccountData?.consult_credits_count ?? 0;
    const isAnyTinyPlus =
      !!mainAccountData?.membership_plan &&
      [
        MembershipPlan.TINY_PLUS,
        MembershipPlan.TINY_PLUS_PROGRAMS,
        MembershipPlan.TINY_PLUS_PROACTIVE,
      ].includes(mainAccountData?.membership_plan);
    if (mainAccountData?.email) {
      query.append('email', mainAccountData.email);
    }
    if (currentKitId) {
      query.append('a1', currentKitId);
    }
    if (mainAccountData?.phone_number) {
      query.append(
        consultCreditsCount > 0 ? 'a6' : 'a5',
        mainAccountData.phone_number,
      );
    }
    let url = '';
    if (consultCreditsCount > 0) {
      url = LINKS.consultationFreeCalendly;
    } else if (isAnyTinyPlus) {
      url = LINKS.consultationCalendly;
    } else {
      url = LINKS.consultationCalendlyFullPrice;
    }
    return `${url}?${query.toString()}`;
  }, [currentKitId, mainAccountData]);

  return consultLink;
};

export const sortRecentToOldestConsult = (a: Consult, b: Consult) =>
  a.scheduled_dt > b.scheduled_dt ? -1 : 1;

const Consults = () => {
  const { data: consultAgentsList } = useConsultAgents();
  const { data: consultsList } = useConsults();
  const consultCreditsCount = useSelector(getConsultCreditsCount);

  const upcomingConsults = useMemo(
    () =>
      consultsList
        ?.filter(consult => CONSULT_ACTIVE_STATUS.includes(consult.status))
        .sort(sortRecentToOldestConsult),
    [consultsList],
  );
  const pastConsults = useMemo(
    () =>
      consultsList
        ?.filter(
          consult =>
            !CONSULT_ACTIVE_STATUS.includes(consult.status) &&
            consult.status !== 'cancelled',
        )
        .sort(sortRecentToOldestConsult),
    [consultsList],
  );
  const cancelledConsults = useMemo(
    () =>
      consultsList
        ?.filter(consult => consult.status === 'cancelled')
        .sort(sortRecentToOldestConsult),
    [consultsList],
  );

  return (
    <>
      {!consultsList?.length && (
        <HeaderIllustration imgSrc={ConsultsSVG} bgColor='#CDEAFA' />
      )}
      <PageContainer
        size={ContainerSizes.XL}
        className={styles.consultsContainer}
      >
        {!!consultsList?.length && (
          <div className='flex flex-column gap-2 mb-4'>
            <Typography variant={'heading-xl'}>Coaching</Typography>
            {!!consultCreditsCount && (
              <div className={styles.iconRow}>
                <Icon name='messageHearthSquare' size='s' />
                <Typography variant={'label'}>
                  {`You have ${consultCreditsCount} free call${
                    consultCreditsCount > 1 ? 's' : ''
                  } remaining.`}
                </Typography>
              </div>
            )}
          </div>
        )}
        <div className={styles.sectionsContainer}>
          <main className={styles.mainContainer}>
            {!consultsList?.length ? (
              <ConsultInfo consultAgentsList={consultAgentsList} />
            ) : (
              <div className='flex flex-column gap-4 mb-4'>
                {!!upcomingConsults?.length && (
                  <div className='flex flex-column gap-3'>
                    <Typography variant='heading-m'>Upcoming</Typography>
                    <div className='flex flex-column gap-3'>
                      {upcomingConsults.map(consult => (
                        <ConsultCard
                          consult={consult}
                          key={consult.id + '_' + consult.calendly_id}
                        />
                      ))}
                    </div>
                  </div>
                )}
                <BookInAdvance upcomingConsults={upcomingConsults} />
                {(!!pastConsults?.length || !!cancelledConsults?.length) && (
                  <div className='flex flex-column gap-3'>
                    <Typography variant='heading-m'>Previous</Typography>
                    <div className='flex flex-column gap-3'>
                      {pastConsults.map(consult => (
                        <ConsultCard consult={consult} key={consult.id} />
                      ))}
                      {cancelledConsults.map(consult => (
                        <ConsultCard consult={consult} key={consult.id} />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {!!consultsList?.length && (
              <GenericSummaryCardWithImage
                section='Coaching sessions'
                title='Navigate your results with our experts'
                image={consultsIllustration}
              >
                <div className='flex align-items-center gap-1'>
                  <LinkButton
                    href='/consults/info'
                    label='Learn more'
                    color={ButtonColors.LIGHT}
                  />
                </div>
              </GenericSummaryCardWithImage>
            )}
          </main>

          <section className={styles.right}>
            <FloatingCTA
              label='Book a session'
              href='/consults/book'
              extra={<ConsultPricing />}
            >
              <div className={styles.blackCard}>
                <div className={styles.cardHeader}>
                  <div className={styles.pill}>20 minute call</div>
                  <div className='flex gap-2'></div>
                </div>
                <Typography variant='heading-m'>
                  Book your 1-on-1 coaching session now
                </Typography>
                <Typography variant='body-s'>
                  Work through your results with our qualified microbiome
                  specialists. If you are being asked to pay for a coaching
                  session but believe you should have credits, please reach out
                  to hello@tinyhealth.com
                </Typography>
                <div className='flex flex-column'>
                  <LinkButton
                    label='Book a session'
                    href='/consults/book'
                    width={'full'}
                    size={ButtonSizes.M}
                    color={ButtonColors.PURPLE}
                  />
                  <ConsultPricing />
                </div>
              </div>
            </FloatingCTA>
          </section>
        </div>
      </PageContainer>
    </>
  );
};

export default Consults;
