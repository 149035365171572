import { Button } from 'components';
import { useSelector } from 'react-redux';
import useImpersonate from 'services/auth/useImpersonate';
import { getAccountEmail, getFullName } from 'store/account';
import { getRole } from 'store/user';

import styles from './ImpersonateBanner.module.scss';

const ImpersonateBanner = () => {
  const fullName = useSelector(getFullName);
  const email = useSelector(getAccountEmail);
  const role = useSelector(getRole);
  const isExternalImpersonate = role !== 'admin' && role !== 'practitioner';
  const { stopImpersonating } = useImpersonate();
  return (
    //don't remove id='impersonate-banner' it is used for styling
    <div id='impersonate-banner' className={styles.container}>
      <div className={styles.right}>
        <h6 className={styles.viewingText}>Viewing</h6>
        <div className={styles.infoRow}>
          <h4>{fullName}</h4>
          <h6 className={styles.email}>{email}</h6>
        </div>
      </div>
      {!isExternalImpersonate && (
        <Button
          className={styles.switchUserButton}
          onClick={() => stopImpersonating()}
        >
          Switch User
        </Button>
      )}
    </div>
  );
};
export default ImpersonateBanner;
